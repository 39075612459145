import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { buildLink } from '@amazeelabs/react-framework-bridge/gatsby'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

import { enhanceStrapiData } from '@/utils/enhanceStrapiData'
import { getSiteData } from '@/utils/siteData'
import { ArticleTypes } from '@/constants/enums'
import Layout from '@/components/layout'
import Sections from '@/components/sections'
import SEO from '@/components/seo'
import HeroRelease from '@/components/sections/HeroRelease'
import ArticleCollectionColumn from '@/components/blocks/ArticleCollectionColumn'

const ReleaseNote = ({ data = {}, pageContext }) => {
	const { t } = useTranslation()
	const { posts, insights, releaseNotes, site, downloads } = data
	const { title, sections, datetime, platforms } =
		enhanceStrapiData(data.strapiContent) || {}
	const global = {
		posts: posts ? posts.nodes : [],
		insights: insights ? insights.nodes : [],
		releaseNotes: releaseNotes ? releaseNotes.nodes : [],
		downloads: downloads ? downloads.nodes : [],
	}

	const latestItems = data.latestItems.nodes || []
	const pageTitle = data.strapiContent.title;

	const heroProps = {
		badgeText: t('Global.releaseNote'),
		headline: pageTitle,
		headlineOrder: 1,
		date: datetime,
		platforms,
	}

	const latestItemsProps = {
		headline: t('Global.latestReleaseNotes'),
		items:
			latestItems.length > 0 &&
			latestItems.map((item) => ({
				headline: item.teaserHeadline || item.title,
				subline: item.subline,
				datetime: item.datetime,
				platforms: item.platforms,
				Link: buildLink({ href: item.route }),
				variant: ArticleTypes.releaseNote,
			})),
	}

	let seo = {};
	let seoTitle = t('SEO.releaseNoteTitle') + ' ' + title;
	let seoDescripton = t('SEO.releaseNoteFallbackDesc', { version: title });
	seo.metaDescription = seoDescripton;

	return (
		<>
			<SEO
				title={seoTitle}
				seo={seo}
				site={site.nodes[0]}
				pageContext={pageContext}
			/>
			<Layout global={getSiteData(data)} pageContext={pageContext}>
				<main>
					<HeroRelease {...heroProps} />
					<div className="box pb-30 grid gap-x-15 gap-y-30 lg:grid-cols-12">
						<div className="min-w-0 lg:col-span-7">
							<Sections sections={sections} global={global} />
						</div>
						<div className="min-w-0 lg:mt-15 lg:col-start-9 lg:col-span-4">
							{latestItemsProps &&
								latestItemsProps.items &&
								latestItemsProps.items.length > 0 && (
									<ArticleCollectionColumn
										className="lgmax:pt-5 lgmax:border-t lgmax:border-t-gray-500 lg:max-w-xxs lg:pl-5 lg:border-l lg:border-l-gray-500"
										{...latestItemsProps}
									/>
								)}
						</div>
					</div>
				</main>
			</Layout>
		</>
	)
}

ReleaseNote.defaultProps = {}

ReleaseNote.propTypes = {
	data: PropTypes.object,
	pageContext: PropTypes.object,
}

export default ReleaseNote

export const query = graphql`
  query ReleaseNoteQuery(
    $id: String!
    $locale: String!
    $formatString: String = "DD.MM.YYYY"
  ) {
    locales: allLocale(filter: { language: { eq: $locale } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site: allStrapiSite(filter: { locale: { eq: $locale } }) {
      nodes {
        ...Site
      }
    }
    header: allStrapiHeader(filter: { locale: { eq: $locale } }) {
      nodes {
        ...Header
      }
    }
    footer: allStrapiFooter(filter: { locale: { eq: $locale } }) {
      nodes {
        ...Footer
      }
    }
    strapiContent: strapiReleaseNote(id: { eq: $id }) {
      route
      title
      subline
      seo {
        ...SharedSeo
      }
      datetime(formatString: $formatString, locale: $locale)
      platforms {
        platformId
      }
      sections {
        ... on STRAPI__COMPONENT_BLOCKS_BADGE_DIVIDER_BLOCK {
          ...BlocksBadgeDividerBlock
        }
        ... on STRAPI__COMPONENT_BLOCKS_HEADING_BLOCK {
          ...BlocksHeadingBlock
        }
        ... on STRAPI__COMPONENT_BLOCKS_HIGHLIGHT_BLOCK {
          ...BlocksHighlightBlock
        }
        ... on STRAPI__COMPONENT_BLOCKS_IMAGE_BLOCK {
          ...BlocksImageBlock
        }
        ... on STRAPI__COMPONENT_BLOCKS_TEXT_BLOCK {
          ...BlocksTextBlock
        }
        ... on STRAPI__COMPONENT_BLOCKS_VIDEO_BLOCK {
          ...BlocksVideoBlock
        }
      }
    }
    latestItems: allStrapiReleaseNote(
      filter: { locale: { eq: $locale }, id: { ne: $id } }
      sort: { fields: datetime, order: DESC }
      limit: 10
    ) {
      nodes {
        route
        title
        subline
        teaserHeadline
        datetime(formatString: $formatString, locale: $locale)
        releasedAt: datetime
        platforms {
          platformId
        }
      }
    }
    downloads: allStrapiDownload(filter: { locale: { eq: $locale } }) {
      nodes {
        ...Download
      }
    }
  }
`
